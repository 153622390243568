.project-container {
    display: flex;
    flex-direction: column;
    margin: 0;
    

}

@media(min-width:768px){
   .project-container{
    flex-direction: row;
    flex-wrap: wrap;
   }
}