.project-card-container {
    width: calc(100% - 4px);
    height: 380px;
    background-color: #000;
    margin: 0;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
    border:2px solid #0E463D;
}
.bg-overlay {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: rgba(0,0,0,0.8); */
    opacity: 0;
    transition: all .2s ease-in-out;
    flex-direction: column;
}
.bg-overlay span {
    color:#fff;
    font-family: "Playfair Display", serif;
    font-weight: 400;
    font-size: 17px;
}

.bg-overlay .title {
    font-size: 40px;
    font-family: "Playfair Display", serif;
    font-weight: 300;
    color:#fff;
    text-align: center;

}
.project-card-container:hover .bg-overlay{
    opacity: 1;
    background-color: rgba(0,0,0,0.6);
}

@media(min-width:760px){
    .project-card-container {
        width: calc(25% - 4px);
        height: 420px;

    }
}

@media(min-width:1800px){
    .project-card-container {
        width: calc(20% - 4px);
        height: 420px;

    }
}