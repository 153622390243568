.projectView-container {
    padding:2%;

}

.project-info {
  padding: 0 20px ;
  z-index: -10;

}

.project-content-container h1{
    font-size: 35px;
    font-weight: 100;
    line-height: 42px;
    font-family: "PT Sans", sans-serif;
    font-style: normal;
    color:rgb(34, 34, 34);

}


.project-info p{
    line-height:24px;
    font-weight:400;
    color:rgb(69, 69, 69);
    font-family: "PT Sans", serif;
    font-size: 16px;
    margin-bottom: 20px;
    margin-right: 10px;

}

div > h2{
    font-size: 35px;
    font-weight: 100;
    line-height: 42px;
    font-family: "PT Sans", sans-serif;
    font-style: normal;
    color:rgb(34, 34, 34);

    text-align: center;

}

.project-info .project-main-descp {

}

.project-content-container {
   display: flex;
   justify-content: center;
   align-items: center;
   margin-top: 65px;
   flex-direction: column;

}
.project-content-container .project-image {
    height: 300px;
    margin-bottom: 20px;
}

.project-content-container .project-image img {
    width: 100%;
    height: 100%;
    background-size: contain;

}


@media(min-width:968px){
    .project-info{
        position: fixed;
        top: 65px;
        left: 20px;
        width: 20%;
        z-index: 100;

    }

    .project-content-container {
          width: 100%;
         display: flex;
         justify-content: center;
         align-items: center;
         margin-top: 65px;
         flex-direction: column;


    }

    .project-content-container .project-image {
        width: 50%;
        height: 50%;
        background-color: #000;


    }



    .project-content-container h1{
        font-size: 55px;
        font-weight: 100;
        line-height: 42px;
        font-family: "PT Sans", sans-serif;
        font-style: normal;
        color:rgb(34, 34, 34);
    }
}