.about-container {
    width: 100%;

}
.about-container p {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color:rgb(69, 69, 69);
    font-family: "PT Serif", serif;
    text-align: justify;
}
.about-info-section {

    display: flex;
    flex-direction: column;


}
.about-info-section .left-section {
    padding: 10px 15px;


}
.about-info-section .right-section {
    padding: 10px 15px;
}

.about-info-section .about-main-title {
    font-size: 25px;
    font-weight: 100;
    line-height: 31.5px;
    color:rgb(34, 34, 34);


}
.about-info-section .quated-title {
    font-size: 22px;
    line-height: 33.6px;
    font-weight: 400;
    color: black;
    font-family: "PT Sans", sans-serif;

}
.left-side-image-container {
    width: 100%;
    height: 300px;

}
.left-side-image-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.right-side-image-container {
    width: 100%;
    height: 350px;
    margin: 20px 0;;

}
.right-side-image-container img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}
.link-section {
    display: flex;
    align-items: center;
    margin:10px 0;

}
.about-link {
    font-size: 12px;
    transition: all .2s ease-in-out;
}
.link-section span {
    font-size: 10px;
    margin-left: 2px;
}
.about-link:hover{
    color: grey;
    text-decoration: underline;
    cursor: pointer;

}
.sub-heading {
    font-size: 15px;
    font-weight: 700;
    line-height: 24px;
    color:rgb(69, 69, 69);
    margin: 15px 0;
}
.bullet-list-container {
    padding: 10px;
}

.about-container li {
     font-size: 13px;
     font-weight: 400;
     line-height: 24px;
     color: rgb(69, 69, 69);
     margin-bottom: 5px;
     margin-left: 10px;
}
.about-info-section .social-container {
    display: flex;
    flex-direction: column;
   align-items: center;
}

@media(min-width:960px){
    .about-container {
        width: 50%;
        margin: 0 auto;

    }
    .about-info-section {
        display: flex;
        flex-direction: column;

    }

    .left-side-image-container {
        width: 100%;
        height: 650px;

    }

    .left-section {
        flex:1;
    }
    .right-section {
        flex:1
    }
    .right-side-image-container {
        width: 100%;
        height: 550px;
        margin: 20px 0;;

    }
    .right-side-image-container img {
        width: 100%;
        height: 100%;
        object-fit: fill;
    }

    .about-container p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color:rgb(69, 69, 69);
        font-family: "PT Serif", serif;
        text-align: justify;
    }
}