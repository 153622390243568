.team-container{
    width: 95%;
    margin: 20px auto;

}
.top-main-section{
    margin: 20px ;
}
.top-main-section .main-section-title {
    font-size: 30px;
    color:rgba(34,34,34,.5);
    font-weight: 100;
    line-height: 42px;
    font-family: "PT Sans", serif;
}

.team-container p {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color:rgb(69, 69, 69);
    font-family: "PT Serif", serif;

}

.team-section {
    display: flex;
    flex-direction: column;
}
.team-card-section{
    padding: 10px 0;
    display: flex;
    flex-direction: column;

}
.team-card-section .team-image-container {
    width: 100%;
    height: 380px;
    background-color: #000;
    margin-bottom: 10px;
}

.team-image-container img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}
.team-card-section .team-name {
    color:rgb(31, 31, 31);
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 10px;
    font-family: "PT Sans", serif;
}

.team-card-section .team-sub-heading {
    font-size: 13px;
    line-height: 19.6px;
    font-weight: 400;
    color:rgb(69, 69, 69);
}

.team-card-section p {
    margin-bottom: 20px;
}

.team-card-section .team-date {
    font-size: 12px;
    line-height: 18.2px;
    font-weight: 400;
    color:rgb(69, 69, 69);
}

@media(min-width:960px){
    .team-container{
        width: 70%;
        margin: 20px auto;
    
    }
    .top-main-section .main-section-title {
        font-size: 35px;
    }
    
    .team-container p {
        font-size: 16px;
    
    }
    .team-section {
        flex-direction: row;
    }
    .team-card-section{
       margin: 0 20px;
       width: calc(50% - 40px);
        
    
    }
    .team-card-section .team-image-container {
        width: 100%;
        height: 550px;
        background-color: #000;
        margin-bottom: 10px;
    }
    
    .team-image-container img {
        width: 100%;
        height: 100%;
        object-fit: fill;
    }
    .team-card-section .team-name {
        color:rgb(31, 31, 31);
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 10px;
        font-family: "PT Sans", serif;
    }
    
    .team-card-section .team-sub-heading {
        font-size: 13px;
        line-height: 19.6px;
        font-weight: 400;
        color:rgb(69, 69, 69);
    }
    
    .team-card-section p {
        margin-bottom: 20px;
        font-size: 14px;
    }

}