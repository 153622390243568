.home-container {
    padding:0;
    margin:0;
}
.flex-wrapper{
    display: flex;
    flex-direction: column;
    height: 100vh;
    flex-wrap: wrap;


}

.flex-wrapper div {
    flex:1;

}

.flex-wrapper .left-side {
    background-color: #242424;

}

.flex-wrapper .right-side{
    background-color: #fff;


}

.right-side-content {
    height: 100%;
    display: flex;
    justify-content: center;
    padding: 0 5%;

    flex-direction: column;



}

.right-side-content h1 {
    margin: 0;
    font-family: "Playfair Display", serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    color:rgb(51, 51, 51);
    font-size: 40px;
    text-align: left;

}

.right-side-content p {

    font-size: 16px;
    line-height:16px;
    font-weight:400;
    color:rgba(51, 51, 51, 0.6);
    font-family: "PT Serif", serif;
    font-style: normal;
}
.right-side-content h3 {
    margin-top:10px;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    font-family: "PT Sans", sans-serif;
    font-style: normal;
    color:rgb(51, 51, 51);

}

.link-sections{
    display: flex;
    flex-direction: column;

}
.link-sections div {
    margin-bottom: 5px;
    font-family: "PT Sans", sans-serif;
    font-size: 12px;

    font-weight: 400;
    color:black;
    cursor: pointer;
    transition: color .3s ease-in-out;

}
.socials-icons {
    display: flex;
    flex-direction: row;
    margin-top: 10px;;
}
.socials-icons .sical-icon-name {
    color:black;
    margin-right: 10px;
    font-size: 20px;
    transition: color .3s ease-in-out;
    cursor: pointer;
}
.link-sections div:hover {
  color: grey;
}
.socials-icons .sical-icon-name:hover {
    color: grey;
}

.left-side{
    position: relative;
    margin: 0;



}
.left-side div {
   position: absolute;
   width: 100%;
   height: 40px;
   bottom: 0px;
   left: 0;

   z-index: 100;
   background-color: #000;
   z-index: 1000;


}


@media(min-width:968px){
    .flex-wrapper {
        flex-direction: row;
        flex-wrap: nowrap;
    }

    .flex-wrapper div {
        flex:1;
    }

    .right-side-content{
        padding: 0 20%;

    }
    .right-side-content h1 {
        font-size: 54px;
    }
    .right-side-content h3 {
        font-size: 20px;
        line-height: 20px;
    }
    .right-side-content p {
        font-size: 16px;
        line-height: 22.2px;
    }
    .link-sections{
        flex-direction: row;
        max-height: 0px;
        margin-top: 10px;


    }
    .link-sections div {
        margin: 0;
        margin-right: 0px;
        font-size: 12px;
        padding: 0;
    }

    .socials-icons .sical-icon-name {

        font-size: 16px;
    }
}
