.contactus-container{
    padding: 0 2%;


}

.contact-main {
    margin: 20px 0;
}
.contact-main h2 {
    font-size: 30px;
    color:rgb(69, 69, 69);
    font-family: "PT Sans", sans-serif;
    font-weight: 300;
}
.contact-box {
    padding: 5px;
    margin-bottom: 10px;

}
.contact-box .contact-box-title {
    font-family: "PT Sans", sans-serif;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 2px;
}

.contact-box .contact-box-address {
    font-family: "PT Serif", serif;
    font-weight: 300;
    font-size: 15px;
    margin-bottom: 10px;

}
.contact-box .contact-box-tel {
    font-family: "PT Serif", serif;
    font-weight: 300;
    font-size: 15px;


}
.contact-box .contact-box-email {
    font-family: "PT Serif", serif;
    font-weight: 300;
    font-size: 15px;


}
.contact-us-form {
    margin: 20px 0;
}
.flex-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}
.contact-us-form .formgroup {
    flex:1;
    margin-right: 5px;
}
.formgroup .label {
    font-family: "PT Sans", sans-serif;
    margin-left: 5px;
    margin-bottom: 10px;
}
.contact-us-form .formgroup input {
   width: calc(100% - 10px);
   margin: 0 5px;
   outline: none;
   border: 0;
   height: 40px;
   background-color: #f4f4f4;


}
.contact-us-form button {
    border: none;
    outline: none;
    width: 150px;
    height: 50px;
    font-size: 25px;
    background-color: #fff;
    font-family: "PT Sans", sans-serif;
    text-transform: uppercase;
    border: 3px solid #000;
    margin-top: 20px;

}

@media(min-width:996px){
    .contactus-container{
        padding: 0 30%;


    }
}