.header-container {
    display: none;

   margin-bottom: 50px;
}

.mobile-header {
    display: flex;
    justify-content: space-between;
    height: 50px;
    align-items: center;
    padding: 0 20px;
}
.drawer-list {

    display: block;
    height: 40px;
    display: flex;
    align-items: center;
}
@media(min-width:768px){

.mobile-header {
    display: none;
}
    .header-container {
        display: flex;
        padding: 0 2%;
        height: 80px;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #f4f4f4;


    }
    .header-list {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .header-list div {
        margin-right: 20px;
        font-family: "PT Sans", serif;
        font-size: 14px;
        color:gray;
        transition: all .3s ease-in-out;
        cursor: pointer;
    }
    .header-list div:hover {
        color:black;
        border-bottom: 1px solid black;
    }
    .logo {
        width: 180px;
        height:180px;
    }
    .logo img {
        width: 100%;
        height: 100%;
    }
    .active{
        color:black;
        border-bottom: 1px solid black;
    }
}